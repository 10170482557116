require("core-js/modules/es6.array.index-of");

require("core-js/modules/es6.regexp.match");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.function.name");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.date.to-string");

require("core-js/modules/es6.set");

require("core-js/modules/es6.array.for-each");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.iterator");

require("core-js/modules/es6.object.to-string");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.map");

var hasMap = typeof Map === 'function' && Map.prototype;
var mapSizeDescriptor = Object.getOwnPropertyDescriptor && hasMap ? Object.getOwnPropertyDescriptor(Map.prototype, 'size') : null;
var mapSize = hasMap && mapSizeDescriptor && typeof mapSizeDescriptor.get === 'function' ? mapSizeDescriptor.get : null;
var mapForEach = hasMap && Map.prototype.forEach;
var hasSet = typeof Set === 'function' && Set.prototype;
var setSizeDescriptor = Object.getOwnPropertyDescriptor && hasSet ? Object.getOwnPropertyDescriptor(Set.prototype, 'size') : null;
var setSize = hasSet && setSizeDescriptor && typeof setSizeDescriptor.get === 'function' ? setSizeDescriptor.get : null;
var setForEach = hasSet && Set.prototype.forEach;
var booleanValueOf = Boolean.prototype.valueOf;
var objectToString = Object.prototype.toString;
var bigIntValueOf = typeof BigInt === 'function' ? BigInt.prototype.valueOf : null;

var inspectCustom = require('./util.inspect').custom;

var inspectSymbol = inspectCustom && isSymbol(inspectCustom) ? inspectCustom : null;

module.exports = function inspect_(obj, opts, depth, seen) {
  if (!opts) opts = {};

  if (has(opts, 'quoteStyle') && opts.quoteStyle !== 'single' && opts.quoteStyle !== 'double') {
    throw new TypeError('option "quoteStyle" must be "single" or "double"');
  }

  if (typeof obj === 'undefined') {
    return 'undefined';
  }

  if (obj === null) {
    return 'null';
  }

  if (typeof obj === 'boolean') {
    return obj ? 'true' : 'false';
  }

  if (typeof obj === 'string') {
    return inspectString(obj, opts);
  }

  if (typeof obj === 'number') {
    if (obj === 0) {
      return Infinity / obj > 0 ? '0' : '-0';
    }

    return String(obj);
  }

  if (typeof obj === 'bigint') {
    return String(obj) + 'n';
  }

  var maxDepth = typeof opts.depth === 'undefined' ? 5 : opts.depth;
  if (typeof depth === 'undefined') depth = 0;

  if (depth >= maxDepth && maxDepth > 0 && typeof obj === 'object') {
    return '[Object]';
  }

  if (typeof seen === 'undefined') seen = [];else if (indexOf(seen, obj) >= 0) {
    return '[Circular]';
  }

  function inspect(value, from) {
    if (from) {
      seen = seen.slice();
      seen.push(from);
    }

    return inspect_(value, opts, depth + 1, seen);
  }

  if (typeof obj === 'function') {
    var name = nameOf(obj);
    return '[Function' + (name ? ': ' + name : '') + ']';
  }

  if (isSymbol(obj)) {
    var symString = Symbol.prototype.toString.call(obj);
    return typeof obj === 'object' ? markBoxed(symString) : symString;
  }

  if (isElement(obj)) {
    var s = '<' + String(obj.nodeName).toLowerCase();
    var attrs = obj.attributes || [];

    for (var i = 0; i < attrs.length; i++) {
      s += ' ' + attrs[i].name + '=' + wrapQuotes(quote(attrs[i].value), 'double', opts);
    }

    s += '>';
    if (obj.childNodes && obj.childNodes.length) s += '...';
    s += '</' + String(obj.nodeName).toLowerCase() + '>';
    return s;
  }

  if (isArray(obj)) {
    if (obj.length === 0) return '[]';
    return '[ ' + arrObjKeys(obj, inspect).join(', ') + ' ]';
  }

  if (isError(obj)) {
    var parts = arrObjKeys(obj, inspect);
    if (parts.length === 0) return '[' + String(obj) + ']';
    return '{ [' + String(obj) + '] ' + parts.join(', ') + ' }';
  }

  if (typeof obj === 'object') {
    if (inspectSymbol && typeof obj[inspectSymbol] === 'function') {
      return obj[inspectSymbol]();
    } else if (typeof obj.inspect === 'function') {
      return obj.inspect();
    }
  }

  if (isMap(obj)) {
    var parts = [];
    mapForEach.call(obj, function (value, key) {
      parts.push(inspect(key, obj) + ' => ' + inspect(value, obj));
    });
    return collectionOf('Map', mapSize.call(obj), parts);
  }

  if (isSet(obj)) {
    var parts = [];
    setForEach.call(obj, function (value) {
      parts.push(inspect(value, obj));
    });
    return collectionOf('Set', setSize.call(obj), parts);
  }

  if (isNumber(obj)) {
    return markBoxed(inspect(Number(obj)));
  }

  if (isBigInt(obj)) {
    return markBoxed(inspect(bigIntValueOf.call(obj)));
  }

  if (isBoolean(obj)) {
    return markBoxed(booleanValueOf.call(obj));
  }

  if (isString(obj)) {
    return markBoxed(inspect(String(obj)));
  }

  if (!isDate(obj) && !isRegExp(obj)) {
    var xs = arrObjKeys(obj, inspect);
    if (xs.length === 0) return '{}';
    return '{ ' + xs.join(', ') + ' }';
  }

  return String(obj);
};

function wrapQuotes(s, defaultStyle, opts) {
  var quoteChar = (opts.quoteStyle || defaultStyle) === 'double' ? '"' : "'";
  return quoteChar + s + quoteChar;
}

function quote(s) {
  return String(s).replace(/"/g, '&quot;');
}

function isArray(obj) {
  return toStr(obj) === '[object Array]';
}

function isDate(obj) {
  return toStr(obj) === '[object Date]';
}

function isRegExp(obj) {
  return toStr(obj) === '[object RegExp]';
}

function isError(obj) {
  return toStr(obj) === '[object Error]';
}

function isSymbol(obj) {
  return toStr(obj) === '[object Symbol]';
}

function isString(obj) {
  return toStr(obj) === '[object String]';
}

function isNumber(obj) {
  return toStr(obj) === '[object Number]';
}

function isBigInt(obj) {
  return toStr(obj) === '[object BigInt]';
}

function isBoolean(obj) {
  return toStr(obj) === '[object Boolean]';
}

var hasOwn = Object.prototype.hasOwnProperty || function (key) {
  return key in this;
};

function has(obj, key) {
  return hasOwn.call(obj, key);
}

function toStr(obj) {
  return objectToString.call(obj);
}

function nameOf(f) {
  if (f.name) return f.name;
  var m = String(f).match(/^function\s*([\w$]+)/);
  if (m) return m[1];
}

function indexOf(xs, x) {
  if (xs.indexOf) return xs.indexOf(x);

  for (var i = 0, l = xs.length; i < l; i++) {
    if (xs[i] === x) return i;
  }

  return -1;
}

function isMap(x) {
  if (!mapSize) {
    return false;
  }

  try {
    mapSize.call(x);

    try {
      setSize.call(x);
    } catch (s) {
      return true;
    }

    return x instanceof Map; // core-js workaround, pre-v2.5.0
  } catch (e) {}

  return false;
}

function isSet(x) {
  if (!setSize) {
    return false;
  }

  try {
    setSize.call(x);

    try {
      mapSize.call(x);
    } catch (m) {
      return true;
    }

    return x instanceof Set; // core-js workaround, pre-v2.5.0
  } catch (e) {}

  return false;
}

function isElement(x) {
  if (!x || typeof x !== 'object') return false;

  if (typeof HTMLElement !== 'undefined' && x instanceof HTMLElement) {
    return true;
  }

  return typeof x.nodeName === 'string' && typeof x.getAttribute === 'function';
}

function inspectString(str, opts) {
  var s = str.replace(/(['\\])/g, '\\$1').replace(/[\x00-\x1f]/g, lowbyte);
  return wrapQuotes(s, 'single', opts);
}

function lowbyte(c) {
  var n = c.charCodeAt(0);
  var x = {
    8: 'b',
    9: 't',
    10: 'n',
    12: 'f',
    13: 'r'
  }[n];
  if (x) return '\\' + x;
  return '\\x' + (n < 0x10 ? '0' : '') + n.toString(16);
}

function markBoxed(str) {
  return 'Object(' + str + ')';
}

function collectionOf(type, size, entries) {
  return type + ' (' + size + ') {' + entries.join(', ') + '}';
}

function arrObjKeys(obj, inspect) {
  var isArr = isArray(obj);
  var xs = [];

  if (isArr) {
    xs.length = obj.length;

    for (var i = 0; i < obj.length; i++) {
      xs[i] = has(obj, i) ? inspect(obj[i], obj) : '';
    }
  }

  for (var key in obj) {
    if (!has(obj, key)) continue;
    if (isArr && String(Number(key)) === key && key < obj.length) continue;

    if (/[^\w$]/.test(key)) {
      xs.push(inspect(key, obj) + ': ' + inspect(obj[key], obj));
    } else {
      xs.push(key + ': ' + inspect(obj[key], obj));
    }
  }

  return xs;
}