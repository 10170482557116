import "core-js/modules/es6.array.for-each";
import { process, isWatching } from '../ResizeObserverController';
import { global } from './global';
import { queueResizeObserver } from './queueResizeObserver';
var CATCH_FRAMES = 60 / 5;
var observerConfig = {
  attributes: true,
  characterData: true,
  childList: true,
  subtree: true
};
var events = ['resize', 'load', 'transitionend', 'animationend', 'animationstart', 'animationiteration', 'keyup', 'keydown', 'mouseup', 'mousedown', 'mouseover', 'mouseout', 'blur', 'focus'];
var scheduled = false;

var Scheduler = function () {
  function Scheduler() {
    var _this = this;

    this.stopped = true;

    this.listener = function () {
      return _this.schedule();
    };
  }

  Scheduler.prototype.run = function (frames) {
    var _this = this;

    if (scheduled) {
      return;
    }

    scheduled = true;
    queueResizeObserver(function () {
      var elementsHaveResized = false;

      try {
        elementsHaveResized = process();
      } finally {
        scheduled = false;

        if (!isWatching()) {
          return;
        }

        if (elementsHaveResized) {
          _this.run(60);
        } else if (frames) {
          _this.run(frames - 1);
        } else {
          _this.start();
        }
      }
    });
  };

  Scheduler.prototype.schedule = function () {
    this.stop();
    this.run(CATCH_FRAMES);
  };

  Scheduler.prototype.observe = function () {
    var _this = this;

    var cb = function cb() {
      return _this.observer && _this.observer.observe(document.body, observerConfig);
    };

    document.body ? cb() : global.addEventListener('DOMContentLoaded', cb);
  };

  Scheduler.prototype.start = function () {
    var _this = this;

    if (this.stopped) {
      this.stopped = false;
      this.observer = new MutationObserver(this.listener);
      this.observe();
      events.forEach(function (name) {
        return global.addEventListener(name, _this.listener, true);
      });
    }
  };

  Scheduler.prototype.stop = function () {
    var _this = this;

    if (!this.stopped) {
      this.observer && this.observer.disconnect();
      events.forEach(function (name) {
        return global.removeEventListener(name, _this.listener, true);
      });
      this.stopped = true;
    }
  };

  return Scheduler;
}();

var scheduler = new Scheduler();
export { scheduler };