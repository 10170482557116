import { calculateBoxSizes } from './algorithms/calculateBoxSize';

var ResizeObserverEntry = function () {
  function ResizeObserverEntry(target) {
    var boxes = calculateBoxSizes(target);
    this.target = target;
    this.contentRect = boxes.contentRect;
    this.borderBoxSize = boxes.borderBoxSize;
    this.contentBoxSize = boxes.contentBoxSize;
  }

  return ResizeObserverEntry;
}();

export { ResizeObserverEntry };