import "core-js/modules/es6.array.index-of";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.map";
import { scheduler } from './utils/scheduler';
import { ResizeObservation } from './ResizeObservation';
import { ResizeObserverDetail } from './ResizeObserverDetail';
import { hasActiveObservations } from './algorithms/hasActiveObservations';
import { hasSkippedObservations } from './algorithms/hasSkippedObservations';
import { deliverResizeLoopError } from './algorithms/deliverResizeLoopError';
import { broadcastActiveObservations } from './algorithms/broadcastActiveObservations';
import { gatherActiveObservationsAtDepth } from './algorithms/gatherActiveObservationsAtDepth';
var resizeObservers = [];
var observerMap = new Map();
var watching = 0;

var updateCount = function updateCount(n) {
  !watching && n > 0 && scheduler.start();
  watching += n;
  !watching && scheduler.stop();
};

var getObservationIndex = function getObservationIndex(observationTargets, target) {
  for (var i = 0; i < observationTargets.length; i += 1) {
    if (observationTargets[i].target === target) {
      return i;
    }
  }

  return -1;
};

var process = function process() {
  var depth = 0;
  gatherActiveObservationsAtDepth(depth);

  while (hasActiveObservations()) {
    depth = broadcastActiveObservations();
    gatherActiveObservationsAtDepth(depth);
  }

  if (hasSkippedObservations()) {
    deliverResizeLoopError();
  }

  return depth > 0;
};

var ResizeObserverController = function () {
  function ResizeObserverController() {}

  ResizeObserverController.connect = function (resizeObserver, callback) {
    var detail = new ResizeObserverDetail(resizeObserver, callback);
    resizeObservers.push(detail);
    observerMap.set(resizeObserver, detail);
  };

  ResizeObserverController.observe = function (resizeObserver, target, options) {
    if (observerMap.has(resizeObserver)) {
      var detail = observerMap.get(resizeObserver);

      if (getObservationIndex(detail.observationTargets, target) < 0) {
        detail.observationTargets.push(new ResizeObservation(target, options && options.box));
        updateCount(1);
        scheduler.schedule();
      }
    }
  };

  ResizeObserverController.unobserve = function (resizeObserver, target) {
    if (observerMap.has(resizeObserver)) {
      var detail = observerMap.get(resizeObserver);
      var index = getObservationIndex(detail.observationTargets, target);

      if (index >= 0) {
        detail.observationTargets.splice(index, 1);
        updateCount(-1);
      }
    }
  };

  ResizeObserverController.disconnect = function (resizeObserver) {
    if (observerMap.has(resizeObserver)) {
      var detail = observerMap.get(resizeObserver);
      resizeObservers.splice(resizeObservers.indexOf(detail), 1);
      observerMap["delete"](resizeObserver);
      updateCount(-detail.observationTargets.length);
    }
  };

  return ResizeObserverController;
}();

var isWatching = function isWatching() {
  return !!watching;
};

export { ResizeObserverController, resizeObservers, process, isWatching };