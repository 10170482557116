import "core-js/modules/es6.array.for-each";
var trigger;
var callbacks = [];

var notify = function notify() {
  return callbacks.splice(0).forEach(function (cb) {
    return cb();
  });
};

var queueMicroTask = function queueMicroTask(callback) {
  if (!trigger) {
    var el_1 = document.createTextNode('');
    var config = {
      characterData: true
    };
    new MutationObserver(function () {
      return notify();
    }).observe(el_1, config);

    trigger = function trigger() {
      el_1.textContent = '';
    };
  }

  callbacks.push(callback);
  trigger();
};

export { queueMicroTask };