import { isHidden } from '../utils/element';

var calculateDepthForNode = function calculateDepthForNode(node) {
  if (isHidden(node)) {
    return Infinity;
  }

  var depth = 0;
  var parent = node.parentNode;

  while (parent) {
    depth += 1;
    parent = parent.parentNode;
  }

  return depth;
};

export { calculateDepthForNode };